<template>
  <c-box
    id="DetailClientQuizionaryPage"
    position="relative"
    w="100%"
    mx="auto"
    :bg="['#FFF', '#FFF']"
    border="1px solid #f2f2f2"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :p="['1rem', '1.5rem']"
    :min-height="['calc(100vh - 62px)', '74vh']"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        padding="20px 90px"
        :paths="[
          { label: 'Beranda', href: { name: 'nutrisionist.managementClient' } },
          { label: 'Manajemen Klien', href: { name: 'nutrisionist.managementClient' } },
          { label: 'Detail Klien', href: { name: 'nutri.detail-client', query: $route.query } },
          { label: 'Formulir Gizi', isCurrent: true },
        ]"
      />  
    </Portal>
    
    <QuizionaryContent 
      :role="authRole"
    />
  </c-box>
</template>

<script>
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import QuizionaryContent from '@/components/quizionary/content'

export default {
  name: 'NutriQuizionaryDetail',
  components: {
    BreadcrumbPath,
    QuizionaryContent,
  },
  computed: {
    programId() {
      return this.$route.params.programId
    },
  },
}
</script>
